import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container, Form, Row
} from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContaColumn from '../components/ContaColumn';
import DebitoCreditoColumn from '../components/DebitoCreditoColumn';
import DinheiroColumn from '../components/DinheiroColumn';
import HeaderColumn from '../components/HeaderColumn';
import { useAuth } from '../contexts/Auth';
import { exportExtratos, getExtratos } from '../services/extratos';

function Extratos() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(20000);
  const [filters, setFilters] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filterDebitoOuCredito, setFilterDebitoOuCredito] = useState('');
  const [filterValor, setFilterValor] = useState('');
  const [filterCategoria, setFilterCategoria] = useState('');
  const [filterSubCategoria, setFilterSubCategoria] = useState('');
  const [filterAgencia, setFilterAgencia] = useState('');
  const [filterConta, setFilterConta] = useState('');
  const [filterCpf, setFilterCpf] = useState('');
  const [filterNome, setFilterNome] = useState('');

  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'data',
      text: 'DATA',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'debito_ou_credito',
      text: 'DEB/CRED',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: DebitoCreditoColumn
    },
    {
      dataField: 'valor',
      text: 'VALOR',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: DinheiroColumn
    },
    {
      dataField: 'categoria',
      text: 'CATEGORIA',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'subcategoria',
      text: 'SUB CATEGORIA',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'agencia_extrato',
      text: 'AGÊNCIA',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'conta_extrato',
      text: 'CONTA',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: ContaColumn
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'nome',
      text: 'NOME',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'historico',
      text: 'HISTORICO',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'documento',
      text: 'DOCUMENTO',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'observacoes',
      text: 'OBSERVAÇÕES',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    }
  ];

  const handleTableChange = async (type, event) => {
    setInProgress(true);
    let filterByEventType = {};
    if (type === 'search') {
      filterByEventType = event.filters;
    } else if (type === 'pagination') {
      filterByEventType = filters;
    }

    const response = await getExtratos(
      auth.user.accessToken,
      event.page,
      event.sizePerPage,
      filterByEventType
    );

    if (response.status !== 200) {
      toast.error('Ops!!! ocorreu um erro inesperado');
      console.error(response);
    }

    setItems(response.data.items);
    setPage(response.data.page);
    setPageSize(response.data.page_size);
    setTotalItems(response.data.total_items);
    if (type === 'search') {
      setFilters(event.filters);
    }
    setInProgress(false);
  };

  const handleInitialData = async () => handleTableChange('initial', { page, sizePerPage: pageSize });

  useEffect(() => {
    handleInitialData();
  }, []);

  const handleSizePerPageChange = (sizePerPage) => {
    setPageSize(sizePerPage);
    handleTableChange('changeSizePerPage', { page, sizePerPage });
  };

  const handleClickSearch = () => {
    setInProgress(true);
    setPage(1);
    const event = {
      page,
      sizePerPage: pageSize,
      filters: {}
    };

    if (filterData !== '' && filterData != null) {
      const date = moment(filterData, 'DD/MM/YYYY');
      if (date.isValid()) {
        event.filters.data = {
          filterType: 'DATE',
          filterVal: {
            date,
            comparator: '='
          }
        };
      } else {
        toast.warn(`Ops!!! data [${filterData}] inválida filtro ignorado!!!`);
      }
    }
    if (filterDebitoOuCredito !== '') {
      event.filters.debito_ou_credito = {
        filterType: 'STATUS',
        filterVal: filterDebitoOuCredito
      };
    }
    if (filterValor !== '') {
      if (Number.isNaN(filterValor)) {
        toast.warn(`Ops!!! valor [${filterValor}] inválido filtro ignorado!!!`);
      } else {
        event.filters.valor = { filterVal: filterValor };
      }
    }
    if (filterCategoria !== '') {
      event.filters.categoria = {
        filterType: 'STATUS',
        filterVal: filterCategoria
      };
    }
    if (filterSubCategoria !== '') {
      event.filters.subcategoria = {
        filterType: 'STATUS',
        filterVal: filterSubCategoria
      };
    }
    if (filterAgencia !== '') {
      event.filters.agencia_extrato = { filterVal: filterAgencia };
    }
    if (filterConta !== '') {
      event.filters.conta_extrato = { filterVal: filterConta };
    }
    if (filterCpf !== '') {
      event.filters.cpf = { filterVal: filterCpf };
    }
    if (filterNome !== '') {
      event.filters.nome = { filterVal: filterNome };
    }

    handleTableChange('search', event);
  };

  const handleClickClearFilters = () => {
    setInProgress(true);
    setFilters(null);
    setFilterData(null);
    setFilterDebitoOuCredito('');
    setFilterValor('');
    setFilterCategoria('');
    setFilterSubCategoria('');
    setFilterAgencia('');
    setFilterConta('');
    setFilterCpf('');
    setFilterNome('');
    setPage(1);

    const event = {
      page,
      sizePerPage: pageSize
    };
    handleTableChange('reset', event);
  };

  const handleClickRequestReport = async () => {
    setInProgress(true);

    const response = await exportExtratos(auth.user.accessToken, filters);

    if (response.status !== 201) {
      toast.error('Ops!!! ocorreu um erro inesperado');
    }

    navigate('/solicitacoes-relatorio');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Associados</small>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small>Lista</small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Data</Form.Label>
                    <MaskedFormControl
                      type="text"
                      size="sm"
                      mask="11/11/1111"
                      placeholder="dd/mm/aaaa"
                      value={filterData}
                      onChange={(e) => setFilterData(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group>
                    <Form.Label>Débito/Crédito</Form.Label>
                    <Form.Select
                      value={filterDebitoOuCredito}
                      onChange={(e) => setFilterDebitoOuCredito(e.target.value)}
                      defaultValue=""
                      size="sm"
                    >
                      <option value="">Selecione</option>
                      <option value="D">Débito</option>
                      <option value="C">Crédito</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group>
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                      size="sm"
                      type="number"
                      placeholder="00.00"
                      value={filterValor}
                      onChange={(e) => setFilterValor(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Categoria</Form.Label>
                    <Form.Select
                      value={filterCategoria}
                      onChange={(e) => setFilterCategoria(e.target.value)}
                      defaultValue=""
                      size="sm"
                    >
                      <option value="">Selecione</option>
                      <option value="Administrativo">Administrativo</option>
                      <option value="Depósito bloqueado">Depósito bloqueado</option>
                      <option value="Desp Bancárias">Desp Bancárias</option>
                      <option value="Despesas">Despesas</option>
                      <option value="Despesas nao identificadas">Despesas nao identificadas</option>
                      <option value="Devolção">Devolção</option>
                      <option value="Devolução">Devolução</option>
                      <option value="Devoluções">Devoluções</option>
                      <option value="Eventos">Eventos</option>
                      <option value="Fornecedores">Fornecedores</option>
                      <option value="Impostos">Impostos</option>
                      <option value="Investimentos">Investimentos</option>
                      <option value="Não categorizado">Não categorizado</option>
                      <option value="Receitas">Receitas</option>
                      <option value="Recursos Humanos">Recursos Humanos</option>
                      <option value="Relatório de Despesas">Relatório de Despesas</option>
                      <option value="Sorteio">Sorteio</option>
                      <option value="Tarifas Bancárias">Tarifas Bancárias</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Sub Categoria</Form.Label>
                    <Form.Select
                      value={filterSubCategoria}
                      onChange={(e) => setFilterSubCategoria(e.target.value)}
                      defaultValue=""
                      size="sm"
                    >
                      <option value="">Selecione</option>
                      <option value="Alimentação">Alimentação</option>
                      <option value="CDB">CDB</option>
                      <option value="Contador">Contador</option>
                      <option value="Depósito bloqueado">Depósito bloqueado</option>
                      <option value="Encargos Sociais">Encargos Sociais</option>
                      <option value="Estorno de mensalidade">Estorno de mensalidade</option>
                      <option value="Evento">Evento</option>
                      <option value="Fornecedores">Fornecedores</option>
                      <option value="Fundos de Investimentos">Fundos de Investimentos</option>
                      <option value="Impostos">Impostos</option>
                      <option value="Pagamento a fornecedores">Pagamento a fornecedores</option>
                      <option value="Receitas">Receitas</option>
                      <option value="Reembolso de despesas">Reembolso de despesas</option>
                      <option value="Resgate Investimento">Resgate Investimento</option>
                      <option value="Salários">Salários</option>
                      <option value="Sistemas">Sistemas</option>
                      <option value="Sorteio">Sorteio</option>
                      <option value="Tarifas Bancárias">Tarifas Bancárias</option>
                      <option value="Transporte de Saldo">Transporte de Saldo</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group>
                    <Form.Label>Agência</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="9999"
                      value={filterAgencia}
                      onChange={(e) => setFilterAgencia(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={1}>
                  <Form.Group>
                    <Form.Label>Conta</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="9999"
                      value={filterConta}
                      onChange={(e) => setFilterConta(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>CPF</Form.Label>
                    <MaskedFormControl
                      type="text"
                      size="sm"
                      mask="11111111111"
                      placeholder="99999999999"
                      value={filterCpf}
                      onChange={(e) => setFilterCpf(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder=""
                      value={filterNome}
                      onChange={(e) => setFilterNome(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2}>
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={inProgress}
                    onClick={handleClickSearch}
                  >
                    Pesquisar
                    {' '}
                    <i className="fas fa-search" />
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    size="sm"
                    variant="secondary"
                    disabled={inProgress}
                    onClick={handleClickClearFilters}
                  >
                    Limpar Filtros
                    {' '}
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    size="sm"
                    variant="success"
                    disabled={inProgress}
                    onClick={handleClickRequestReport}
                  >
                    Solicitar Exportação Relatório
                    {' '}
                    <i className="fas fa-file" />
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                remote
                bordered
                hover
                condensed
                wrapperClasses="table-responsive"
                headerClasses="text-nowrap text-white bg-primary"
                rowClasses="text-nowrap"
                keyField="id"
                loading={inProgress}
                columns={columns}
                data={items}
                pagination={paginationFactory({
                  page,
                  sizePerPage: pageSize,
                  onSizePerPageChange: handleSizePerPageChange,
                  totalSize: totalItems
                })}
                filter={filterFactory()}
                noDataIndication="Nenhum resultado encontrado!!!"
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: 'rgba(66, 133, 244, 0.3)'
                    })
                  }
                })}
                onTableChange={handleTableChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Extratos;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function PlanoColumn(cell, row) {
  let variant = 'secondary';
  if (cell?.toUpperCase() === 'PECÚLIO') {
    variant = 'secondary';
  } else if (cell?.toUpperCase() === 'FUTURO') {
    variant = 'primary';
  } else if (cell?.toUpperCase() === 'PECÚLIO/FUTURO') {
    variant = 'info';
  } else if (cell?.toUpperCase() === 'EMPRESARIAL') {
    variant = 'success';
  }

  return <Badge bg={variant}>{cell?.toUpperCase()}</Badge>;
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function ActiveColumn(cell, row) {
  if (cell === '0' || cell === 0) {
    return <Badge bg="secondary">Não</Badge>;
  }

  return <Badge bg="success">Sim</Badge>;
}

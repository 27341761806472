/* eslint-disable react/destructuring-assignment */

// eslint-disable-next-line no-unused-vars
export default function SolicitacaoRelatorioColumn(cell, row) {
  switch (cell) {
    case 'relatorio_extratos_exportacao':
      return 'Relatório de Extratos';
    case 'relatorio_associados_exportacao':
      return 'Relatório de Associados';
    default:
      return cell;
  }
}

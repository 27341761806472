/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import moment from 'moment';

function handleTextFilter(fieldName, fieldValue) {
  return {
    logic: 'AND',
    clause: `${fieldName} LIKE '%${fieldValue}%'`
  };
}

function handleDateFilter(fieldName, fieldValue) {
  const { comparator, date } = fieldValue;
  return {
    logic: 'AND',
    clause: `${fieldName} ${comparator} '${moment.utc(date, 'DD/MM/YYYY').format('DD/MM/YYYY')}'`
  };
}

function handleStatusFilter(fieldName, fieldValue) {
  return {
    logic: 'AND',
    clause: `${fieldName} = '${fieldValue}'`
  };
}

export function exportExtratos(accessToken, filters) {
  const payload = {
    table: 'relatorio_extratos_exportacao',
    filters: []
  };

  if (filters !== null && filters !== undefined) {
    for (const [key, value] of Object.entries(filters)) {
      switch (value.filterType) {
        case 'DATE':
          if (moment.utc(value.filterVal.date).isValid()) {
            payload.filters.push(handleDateFilter(key, value.filterVal));
          }
          break;
        case 'STATUS':
          payload.filters.push(handleStatusFilter(key, value.filterVal));
          break;
        default:
          payload.filters.push(handleTextFilter(key, value.filterVal));
          break;
      }
    }
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };
  console.log(payload);
  return axios.post(`${process.env.REACT_APP_API_URL_BASE}/v1/exports`, payload, config);
}

export function getExtratos(
  accessToken,
  page,
  pageSize,
  filters
) {
  const payload = {
    page: page === null ? 1 : page,
    page_size: pageSize === null ? 10 : pageSize,
    table: 'relatorio_extratos',
    fields: [
      'id',
      'categoria',
      'subcategoria',
      'data',
      'historico',
      'documento',
      'debito_ou_credito',
      'valor',
      'observacoes',
      'agencia_extrato',
      'conta_extrato',
      'conta_digito',
      'cpf',
      'nome'
    ],
    order_by: {
      field: 'id',
      order: 'DESC'
    },
    filters: []
  };

  if (filters !== null && filters !== undefined) {
    for (const [key, value] of Object.entries(filters)) {
      switch (value.filterType) {
        case 'DATE':
          if (moment.utc(value.filterVal.date).isValid()) {
            payload.filters.push(handleDateFilter(key, value.filterVal));
          }
          break;
        case 'STATUS':
          payload.filters.push(handleStatusFilter(key, value.filterVal));
          break;
        default:
          payload.filters.push(handleTextFilter(key, value.filterVal));
          break;
      }
    }
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios.post(`${process.env.REACT_APP_API_URL_BASE}/v1/reports`, payload, config);
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function SituacaoSolicitacaoRelatorioColumn(cell, row) {
  switch (cell) {
    case 1:
      return <Badge bg="warning">INICIADO</Badge>;
    case 2:
      return <Badge bg="primary">EM PROCESSAMENTO</Badge>;
    case 3:
      return <Badge bg="success">FINALIZADO</Badge>;
    case 4:
      return <Badge bg="danger">FALHA</Badge>;
    default:
      return <Badge bg="secondary">PENDENTE</Badge>;
  }
}

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container, Form, Row
} from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderColumn from '../components/HeaderColumn';
import PlanoColumn from '../components/PlanoColumn';
import SituacaoAssociadoColumn from '../components/SituacaoAssociadoColumn';
import { useAuth } from '../contexts/Auth';
import { exportAssociados, getAssociados } from '../services/associados';

function Associados() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(20000);
  const [filters, setFilters] = useState(null);
  const [filterCpf, setFilterCpf] = useState('');
  const [filterNome, setFilterNome] = useState('');
  const [filterEmailPrincipal, setFilterEmailPrincipal] = useState('');
  const [filterEmailSecundario, setFilterEmailSecundario] = useState('');
  const [filterDataListaAnviva, setFilterDataListaAnviva] = useState(null);
  const [filterSituacao, setFilterSituacao] = useState('');
  const [filterPlano, setFilterPlano] = useState('');

  const columns = [
    {
      dataField: 'cpf',
      text: 'CPF',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'nome',
      text: 'NOME',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'email_principal',
      text: 'E-MAIL PRINCIPAL',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'email_secundario',
      text: 'E-MAIL SECUNDÁRIO',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'data_lista_anviva',
      text: 'DATA LISTA ANVIVA',
      classes: 'text-center',
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'situacao',
      text: 'SITUAÇÃO',
      classes: 'text-center',
      formatter: SituacaoAssociadoColumn,
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'plano',
      text: 'PLANO',
      classes: 'text-center',
      formatter: PlanoColumn,
      headerFormatter: HeaderColumn
    }
  ];

  const handleTableChange = async (type, event) => {
    setInProgress(true);
    let filterByEventType = {};
    if (type === 'search') {
      filterByEventType = event.filters;
    } else if (type === 'pagination') {
      filterByEventType = filters;
    }

    const response = await getAssociados(
      auth.user.accessToken,
      event.page,
      event.sizePerPage,
      filterByEventType
    );

    if (response.status !== 200) {
      toast.error('Ops!!! ocorreu um erro inesperado');
      console.error(response);
    }

    setItems(response.data.items);
    setPage(response.data.page);
    setPageSize(response.data.page_size);
    setTotalItems(response.data.total_items);
    if (type === 'search') {
      setFilters(event.filters);
    }
    setInProgress(false);
  };

  const handleInitialData = async () => handleTableChange('initial', { page, sizePerPage: pageSize });

  useEffect(() => {
    handleInitialData();
  }, []);

  const handleSizePerPageChange = (sizePerPage) => {
    setPageSize(sizePerPage);
    handleTableChange('changeSizePerPage', { page, sizePerPage });
  };

  const handleClickSearch = () => {
    setInProgress(true);
    setPage(1);
    const event = {
      page,
      sizePerPage: pageSize,
      filters: {}
    };

    if (filterCpf !== '') {
      event.filters.cpf = { filterVal: filterCpf };
    }
    if (filterNome !== '') {
      event.filters.nome = { filterVal: filterNome };
    }
    if (filterEmailPrincipal !== '') {
      event.filters.email_principal = { filterVal: filterEmailPrincipal };
    }
    if (filterEmailSecundario !== '') {
      event.filters.email_secundario = { filterVal: filterEmailSecundario };
    }
    if (filterSituacao !== '') {
      event.filters.situacao = { filterVal: filterSituacao };
    }
    if (filterPlano !== '') {
      event.filters.plano = { filterVal: filterPlano };
    }

    if (filterDataListaAnviva !== '' && filterDataListaAnviva != null) {
      const date = moment(filterDataListaAnviva, 'DD/MM/YYYY');
      if (date.isValid()) {
        event.filters.data_lista_anviva = {
          filterType: 'DATE',
          filterVal: {
            date,
            comparator: '='
          }
        };
      } else {
        toast.warn(`Ops!!! data [${filterDataListaAnviva}] inválida filtro ignorado!!!`);
      }
    }

    handleTableChange('search', event);
  };

  const handleClickClearFilters = async () => {
    setInProgress(true);
    setFilters(null);
    setFilterCpf('');
    setFilterNome('');
    setFilterEmailPrincipal('');
    setFilterEmailSecundario('');
    setFilterDataListaAnviva(null);
    setFilterSituacao('');
    setFilterPlano('');
    setPage(1);

    const event = {
      page,
      sizePerPage: pageSize
    };
    handleTableChange('reset', event);
  };

  const handleClickRequestReport = async () => {
    setInProgress(true);

    const response = await exportAssociados(auth.user.accessToken, filters);

    if (response.status !== 201) {
      toast.error('Ops!!! ocorreu um erro inesperado');
    }

    navigate('/solicitacoes-relatorio');
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Associados</small>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small>Lista</small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>CPF</Form.Label>
                    <MaskedFormControl
                      type="text"
                      size="sm"
                      mask="11111111111"
                      placeholder="99999999999"
                      value={filterCpf}
                      onChange={(e) => setFilterCpf(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Filtro por nome..."
                      value={filterNome}
                      onChange={(e) => setFilterNome(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>E-mail Principal</Form.Label>
                    <Form.Control
                      size="sm"
                      type="email"
                      placeholder="Filtro por e-mail principal..."
                      value={filterEmailPrincipal}
                      onChange={(e) => setFilterEmailPrincipal(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>E-mail Secundário</Form.Label>
                    <Form.Control
                      size="sm"
                      type="email"
                      placeholder="Filtro por e-mail secundário..."
                      value={filterEmailSecundario}
                      onChange={(e) => setFilterEmailSecundario(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Data Lista Anviva</Form.Label>
                    <MaskedFormControl
                      type="text"
                      size="sm"
                      mask="11/11/1111"
                      placeholder="dd/mm/aaaa"
                      value={filterDataListaAnviva}
                      onChange={(e) => setFilterDataListaAnviva(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Situação</Form.Label>
                    <Form.Select
                      value={filterSituacao}
                      onChange={(e) => setFilterSituacao(e.target.value)}
                      defaultValue=""
                      size="sm"
                    >
                      <option value="">Selecione</option>
                      <option value="Ativo/Titular">Ativo/Titular</option>
                      <option value="Inativo">Inativo</option>
                      <option value="Em admissão">Em admissão</option>
                      <option value="Falecido">Falecido</option>
                      <option value="Pecúlio Finalizado">Pecúlio Finalizado</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Plano</Form.Label>
                    <Form.Select
                      value={filterPlano}
                      onChange={(e) => setFilterPlano(e.target.value)}
                      defaultValue=""
                      size="sm"
                    >
                      <option value="">Selecione</option>
                      <option value="Pecúlio">Pecúlio</option>
                      <option value="Futuro">Futuro</option>
                      <option value="Pecúlio/Futuro">Pecúlio/Futuro</option>
                      <option value="Empresarial">Empresarial</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2}>
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={inProgress}
                    onClick={handleClickSearch}
                  >
                    Pesquisar
                    {' '}
                    <i className="fas fa-search" />
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    size="sm"
                    variant="secondary"
                    disabled={inProgress}
                    onClick={handleClickClearFilters}
                  >
                    Limpar Filtros
                    {' '}
                    <i className="fas fa-trash" />
                  </Button>
                </Col>
                <Col md={3}>
                  <Button
                    size="sm"
                    variant="success"
                    disabled={inProgress}
                    onClick={handleClickRequestReport}
                  >
                    Solicitar Exportação Relatório
                    {' '}
                    <i className="fas fa-file" />
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                remote
                bordered
                hover
                condensed
                wrapperClasses="table-responsive"
                headerClasses="text-nowrap text-white bg-primary"
                rowClasses="text-nowrap"
                keyField="cpf"
                loading={inProgress}
                columns={columns}
                data={items}
                pagination={paginationFactory({
                  page,
                  sizePerPage: pageSize,
                  onSizePerPageChange: handleSizePerPageChange,
                  totalSize: totalItems
                })}
                filter={filterFactory()}
                noDataIndication="Nenhum resultado encontrado!!!"
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: 'rgba(66, 133, 244, 0.3)'
                    })
                  }
                })}
                onTableChange={handleTableChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Associados;

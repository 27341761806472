/* eslint-disable react/prop-types */
import React from 'react';
import { Toast } from 'react-bootstrap';

function Message(props) {
  const {
    show, setShow, message, variant
  } = props;

  return (
    <Toast
      className="d-inline-block m-1"
      bg={variant}
      onClose={() => setShow(null)}
      show={show != null}
      delay={5000}
      autohide
    >
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
}

export default Message;

/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';

function Sidebar() {
  const auth = useAuth();
  const [isActive, setIsActive] = useState(true);
  const [isActiveMenuAssociados, setIsActiveMenuAssociados] = useState(true);
  const [isActiveMenuFinanceiro, setIsActiveMenuFinanceiro] = useState(true);
  const [isActiveMenuAdministracao, setIsActiveMenuAdministracao] = useState(true);

  const toggleIsActive = () => {
    if (isActive) {
      document.body.classList.remove('sidebar-toggled');
    } else {
      document.body.classList.add('sidebar-toggled');
    }

    setIsActive(!isActive);
  };

  const toggleMenuAssociados = () => setIsActiveMenuAssociados(!isActiveMenuAssociados);

  const toggleMenuFinanceiro = () => setIsActiveMenuFinanceiro(!isActiveMenuFinanceiro);

  const toggleMenuAdministracao = () => setIsActiveMenuAdministracao(!isActiveMenuAdministracao);

  const renderAdministracao = () => {
    if (auth.user.role === 'admin') {
      return (
        <li className="nav-item">
          <a
            onClick={toggleMenuAdministracao}
            className={isActiveMenuAdministracao ? 'nav-link' : 'nav-link collapsed'}
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog" />
            <span>Administração</span>
          </a>
          <div
            id="collapseTwo"
            className={isActiveMenuAdministracao ? 'collapse' : 'collapse show'}
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <NavLink className="collapse-item" to="/usuarios">
                <span>Usuários</span>
              </NavLink>
              <NavLink className="collapse-item" to="/solicitacoes-relatorio">
                <span>Solitações de Relatório</span>
              </NavLink>
            </div>
          </div>
        </li>
      );
    }
  };

  return (
    <ul
      className={isActive ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled'}
      id="accordionSidebar"
    >
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink" />
        </div>
        <div className="sidebar-brand-text mx-3">
          Associe.me
        </div>
      </a>
      <hr className="sidebar-divider my-0" />
      <li className="nav-item active">
        <NavLink className="nav-link" to="/home">
          <i className="fas fa-fw fa-home" />
          <span>Home</span>
        </NavLink>
      </li>
      <hr className="sidebar-divider" />
      <li className="nav-item">
        <a
          onClick={toggleMenuAssociados}
          className={isActiveMenuAssociados ? 'nav-link' : 'nav-link collapsed'}
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i className="fas fa-fw fa-user" />
          <span>Associação</span>
        </a>
        <div
          id="collapseTwo"
          className={isActiveMenuAssociados ? 'collapse' : 'collapse show'}
          aria-labelledby="headingTwo"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item" to="/associados">
              <span>Associados</span>
            </NavLink>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <a
          onClick={toggleMenuFinanceiro}
          className={isActiveMenuFinanceiro ? 'nav-link' : 'nav-link collapsed'}
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i className="fas fa-fw fa-file" />
          <span>Financeiro</span>
        </a>
        <div
          id="collapseTwo"
          className={isActiveMenuFinanceiro ? 'collapse' : 'collapse show'}
          aria-labelledby="headingTwo"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item" to="/extratos">
              <span>Extratos</span>
            </NavLink>
          </div>
        </div>
      </li>
      <hr className="sidebar-divider" />
      {renderAdministracao()}
      <hr className="sidebar-divider d-none d-md-block" />
      <div className="text-center d-none d-md-inline">
        <button
          onClick={toggleIsActive}
          className="rounded-circle border-0"
          id="sidebarToggle"
        />
      </div>
    </ul>
  );
}

export default Sidebar;

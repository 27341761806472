import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container, Form, Row, Spinner
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/Auth';
import { criarNovoUsuario } from '../services/usuarios';

function NovoUsuario() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [active, setActive] = useState(true);

  const handleClickBack = () => navigate('/usuarios');

  const handleValidate = () => {
    let valido = true;
    if (email === '') {
      toast.warn('Informe o e-mail');
      valido = false;
    }

    // eslint-disable-next-line no-useless-escape
    if (!email?.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      toast.warn('E-mail inválido');
      valido = false;
    }

    if (password === '' || confirmPassword === '' || password !== confirmPassword) {
      toast.warn('Verifique Senha/Confirmação Senha');
      valido = false;
    }

    if (role === '') {
      toast.warn('Informe a função');
      valido = false;
    }

    return valido;
  };

  const handleClickSave = async () => {
    setInProgress(true);
    if (handleValidate()) {
      const response = await criarNovoUsuario(
        auth.user.accessToken,
        email,
        password,
        confirmPassword,
        role,
        active
      );

      if (response.status === 500) {
        toast.error('Ocorreu um erro inesperado');
      }

      if (response.status === 409) {
        toast.error(`E-mail: ${email} já existe...`);
      }

      if (response.status === 400) {
        response.data.forEach((x) => {
          toast.warn(x.msg);
        });
      }

      if (response.status === 201) {
        toast.success(`Usuário: ${email} criado com sucesso`);
        handleClickBack();
      }
    }
    setInProgress(false);
  };

  const renderProgress = () => [1, 2, 3].map((number) => (
    <Spinner
      key={`novo-usuario-spinner-${number}`}
      id={`novo-usuario-spinner-${number}`}
      as="span"
      variant="primary"
      animation="grow"
      role="status"
      aria-hidden="true"
    />
  ));

  const renderButtons = () => {
    if (inProgress) {
      return (
        <Card.Footer>
          <Row className="justify-content-center">
            {renderProgress()}
          </Row>
        </Card.Footer>
      );
    }

    return (
      <Card.Footer>
        <Row>
          <Col md={2}>
            <Button
              variant="success"
              size="sm"
              onClick={handleClickSave}
            >
              <i className="fas fa-save" />
              {' '}
              Salvar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              variant="secondary"
              size="sm"
              onClick={handleClickBack}
            >
              <i className="fas fa-arrow-left" />
              {' '}
              Voltar
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Administração</small>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <small>Usuários</small>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small>Novo</small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Novo Usuário
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      size="sm"
                      type="email"
                      placeholder="exemplo@exemplo.com"
                      readOnly={inProgress}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      size="sm"
                      type="password"
                      placeholder="********"
                      readOnly={inProgress}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Confirme Senha</Form.Label>
                    <Form.Control
                      size="sm"
                      type="password"
                      placeholder="********"
                      readOnly={inProgress}
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Função</Form.Label>
                    <Form.Select
                      value={role}
                      onChange={(event) => setRole(event.target.value)}
                      disabled={inProgress}
                      size="sm"
                    >
                      <option value="">Selecione</option>
                      <option value="admin">ADMINISTRADOR</option>
                      <option value="manager">GERENTE</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2}>
                  <Form.Group>
                    <Form.Check
                      type="switch"
                      label="Ativo"
                      disabled={inProgress}
                      checked={active}
                      onChange={(event) => setActive(event.target.checked)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            {renderButtons()}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default NovoUsuario;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';

export default function ContaColumn(cell, row) {
  if (cell === null) {
    return <span />;
  }

  if (row.conta_digito !== null) {
    return <span>{`${cell}-${row.conta_digito}`}</span>;
  }

  return <span>{`${cell}`}</span>;
}

/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import axios from 'axios';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { useAuth } from './contexts/Auth';
import Layout from './layouts/Layout';
import AlterarSenha from './pages/AlterarSenha';
import Associados from './pages/Associados';
import Extratos from './pages/Extratos';
import Home from './pages/Home';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import NovoUsuario from './pages/NovoUsuario';
import SolicitacaoRelatorio from './pages/SolicitacaoRelatorio';
import SolicitacoesRelatorio from './pages/SolicitacoesRelatorio';
import Usuarios from './pages/Usuarios';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={(
          <RequireAuth>
            <Layout />
          </RequireAuth>
        )}
      >
        <Route
          path="/home"
          element={<Home />}
        />
        <Route
          path="/associados"
          element={<Associados />}
        />
        <Route
          path="/extratos"
          element={<Extratos />}
        />
        <Route
          path="/usuarios"
          element={<Usuarios />}
        />
        <Route
          path="/usuarios/novo"
          element={<NovoUsuario />}
        />
        <Route
          path="/usuarios/:id/alterar-senha"
          element={<AlterarSenha />}
        />
        <Route
          path="/solicitacoes-relatorio"
          element={<SolicitacoesRelatorio />}
        />
        <Route
          path="/solicitacoes-relatorio/:id"
          element={<SolicitacaoRelatorio />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    window.location.href = '/login';
  }

  return error.response;
});

function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;

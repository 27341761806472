/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function DebitoCreditoColumn(cell, row) {
  if (cell?.toUpperCase() === 'C') {
    return <Badge bg="success">CRÉDITO</Badge>;
  }

  return <Badge bg="primary">DÉBITO</Badge>;
}

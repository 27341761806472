/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Container, Form, ListGroup, Row, Spinner
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/Auth';
import { consultarSolicitacaoRelatorio, deletarSolicitacaoRelatorio, gerarUrlParaDownloadRelatorio } from '../services/solicitacoesRelatorios';

function SolicitacaoRelatorio() {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [filtros, setFiltros] = useState([]);
  const [relatorio, setRelatorio] = useState('');
  const [situacao, setSituacao] = useState('');
  const [data, setData] = useState('');

  const loadRequestedReport = async (type, event) => {
    setInProgress(true);

    const response = await consultarSolicitacaoRelatorio(auth.user.accessToken, params.id);

    if (response.status !== 200) {
      toast.error('Ops!!! ocorreu um erro inesperado');
    }

    setRelatorio(response.data.table);
    switch (response.data.status) {
      case 1:
        setSituacao('PENDENTE');
        break;
      case 2:
        setSituacao('EM PROCESSAMENTO');
        break;
      case 3:
        setSituacao('FINALIZADO');
        break;
      default:
        setSituacao('FALHA');
        break;
    }
    setData(moment(response.data.status_date).format('DD/MM/YYYY HH:mm:ss'));
    setFiltros(JSON.parse(response.data.filters));
    setInProgress(false);
  };

  const handleLoadRequestedReport = async () => loadRequestedReport('initial');

  useEffect(() => {
    handleLoadRequestedReport();
  }, []);

  const renderFiltros = () => filtros.map((filtro, index) => {
    if (filtro.logic === 'AND') {
      return (
        <ListGroup.Item key={`solicitacao-relatorio-${params.id}-${index}`}>
          <Badge bg="secondary">E</Badge>
          {' '}
          {filtro.clause}
        </ListGroup.Item>
      );
    }
      <ListGroup.Item>
        <Badge bg="primary">OU</Badge>
        {' '}
        {filtro.clause}
      </ListGroup.Item>;
  });

  const handleClickBack = () => navigate('/solicitacoes-relatorio');

  const handleClickDownload = async () => {
    setInProgress(true);
    const response = await gerarUrlParaDownloadRelatorio(auth.user.accessToken, params.id);
    if (response.status !== 200) {
      toast.error('Ops!!! ocorreu um erro inesperado');
    } else {
      toast.success('Sucesso!!! o download iniciará em uma nova aba.');
      window.open(response.data.url, '_blank');
    }
    setInProgress(false);
    handleClickBack();
  };

  const handleClickDelete = async () => {
    setInProgress(true);
    const response = await deletarSolicitacaoRelatorio(auth.user.accessToken, params.id);
    if (response.status !== 204) {
      toast.error('Ops!!! ocorreu um erro inesperado');
    } else {
      toast.info('Relatório excluído com sucesso!!!');
      navigate('/solicitacoes-relatorio');
    }
  };

  const renderProgress = () => [1, 2, 3].map((number) => (
    <Spinner
      key={`solicitacoes-relatorio-spinner-${number}`}
      id={`solicitacoes-relatorio-spinner-${number}`}
      as="span"
      variant="primary"
      animation="grow"
      role="status"
      aria-hidden="true"
    />
  ));

  const renderCard = () => {
    if (inProgress) {
      return (
        <Card>
          <Card.Header>Solicitação de Relatório</Card.Header>
          <Card.Body>
            <Row className="justify-content-center">
              {renderProgress()}
            </Row>
          </Card.Body>
        </Card>
      );
    }

    return (
      <Card>
        <Card.Header>Solicitação de Relatório</Card.Header>
        <Card.Body>
          <Row>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Relatório</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  readOnly
                  value={relatorio}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Situação</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  readOnly
                  value={situacao}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Data</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  readOnly
                  value={data}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2">
            <span className="text">Filtros: </span>
            <ListGroup variant="flush">
              {renderFiltros()}
            </ListGroup>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col md={2}>
              <Button
                variant="primary"
                size="sm"
                onClick={handleClickDownload}
              >
                Baixar
                {' '}
                <i className="fas fa-download" />
              </Button>
            </Col>
            <Col md={2}>
              <Button
                variant="danger"
                size="sm"
                onClick={handleClickDelete}
              >
                Excluir
                {' '}
                <i className="fas fa-remove" />
              </Button>
            </Col>
            <Col md={2}>
              <Button
                variant="secondary"
                size="sm"
                onClick={handleClickBack}
              >
                Voltar
                {' '}
                <i className="fas fa-arrow-left" />
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Administração</small>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small>
                  Solicitação de Relatório /
                  {' '}
                  {' '}
                  {params.id}
                </small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      {renderCard()}
    </Container>
  );
}

export default SolicitacaoRelatorio;

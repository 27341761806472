/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function SituacaoAssociadoColumn(cell, row) {
  let variant = 'secondary';
  if (cell?.toUpperCase() === 'ATIVO/TITULAR') {
    variant = 'success';
  } else if (cell?.toUpperCase() === 'INATIVO') {
    variant = 'danger';
  } else if (cell?.toUpperCase() === 'EM ADMISSÃO') {
    variant = 'primary';
  } else if (cell?.toUpperCase() === 'PECÚLIO FINALIZADO') {
    variant = 'info';
  }

  return <Badge bg={variant}>{cell.toUpperCase()}</Badge>;
}

/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export function paginarUsuarios(
  accessToken,
  searchBy,
  page = 1,
  pageSize = 10
) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  let query = `page=${page}&page_size=${pageSize}`;

  if (searchBy !== null && searchBy !== undefined && searchBy !== '') {
    query += `&search_by=${searchBy}`;
  }

  return axios.get(`${process.env.REACT_APP_API_URL_BASE}/v1/users?${query}`, config);
}

export function criarNovoUsuario(
  accessToken,
  email,
  password,
  confirmPassword,
  role,
  active
) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  const data = {
    email,
    password,
    confirm_password: confirmPassword,
    role,
    active
  };

  return axios.post(`${process.env.REACT_APP_API_URL_BASE}/v1/users`, data, config);
}

export function alterarSenha(
  accessToken,
  id,
  password,
  confirmPassword
) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  const data = {
    password,
    confirm_password: confirmPassword
  };

  return axios.patch(`${process.env.REACT_APP_API_URL_BASE}/v1/users/${id}`, data, config);
}

function toggleActiveUser(accessToken, url) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios.patch(url, {}, config);
}

export function ativarUsuario(accessToken, id) {
  return toggleActiveUser(accessToken, `${process.env.REACT_APP_API_URL_BASE}/v1/users/${id}/activate`);
}

export function inativarUsuario(accessToken, id) {
  return toggleActiveUser(accessToken, `${process.env.REACT_APP_API_URL_BASE}/v1/users/${id}/inactivate`);
}

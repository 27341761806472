import axios from 'axios';

export default function login(email, password) {
  return axios.post(
    `${process.env.REACT_APP_API_URL_BASE}/v1/login`,
    { email, password },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
}

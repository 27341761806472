/* eslint-disable global-require */
import React from 'react';
import {
  Button, Card, Col, Container, Form, Row, Spinner
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import { useAuth } from '../contexts/Auth';

function Login() {
  const navigate = useNavigate();
  const auth = useAuth();

  document.body.classList.add('bg-gradient-primary');

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');

    auth.handleLogin(email, password, () => {
      console.log('logou');
      navigate('/home');
    });
  };

  const renderProgress = () => [1, 2, 3].map((number) => (
    <Spinner
      key={`login-btn-spinner-${number}`}
      id={`login-btn-spinner-${number}`}
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  ));

  const renderSubmitButton = () => {
    if (auth.inProgress) {
      return (
        <Button
          className="btn-user btn-block"
          variant="primary"
          type="submit"
        >
          {renderProgress()}
        </Button>
      );
    }

    return (
      <Button
        className="btn-user btn-block"
        variant="primary"
        type="submit"
      >
        Acessar
      </Button>
    );
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="col-xl-5 col-lg-8 col-md-6">
          <Form onSubmit={handleSubmit}>
            <Card className="o-hidden border-0 shadow-lg my-5">
              <Card.Body className="p-0">
                <Row>
                  <Col>
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="text-gray-900 mb-4">Bem Vindo!</h1>
                        <small className="text-muted">Informe seu e-mail e senha para prosseguir</small>
                        <hr />
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                          disabled={auth.inProgress}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="exemplo@exemplo.com"
                        />
                      </Form.Group>
                      <hr />
                      <Form.Group className="mb-3">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                          disabled={auth.inProgress}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="**********"
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                {renderSubmitButton()}
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col className="col-xl-5 col-lg-8 col-md-6">
          <Message
            variant="warning"
            show={auth.inError}
            setShow={auth.setInError}
            message={auth.inError}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Login;

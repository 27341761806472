/* eslint-disable no-unused-vars */
import moment from 'moment';
import React from 'react';

export default function DateTimeColumn(cell, row) {
  const date = moment(cell);

  if (date.isValid()) {
    return <span>{date.format('DD/MM/YYYY HH:mm:ss')}</span>;
  }
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';

export default function FiltrosColumn(cell, row) {
  const filtros = JSON.parse(cell).map((filtro) => filtro.clause);

  return (
    <span className="text-primary">{filtros}</span>
  );
}

/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container, Row
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate } from 'react-router-dom';
import ActiveColumn from '../components/ActiveColumn';
import Alert from '../components/Alert';
import DateTimeColumn from '../components/DateTimeColumn';
import HeaderColumn from '../components/HeaderColumn';
import RoleColumn from '../components/RoleColumn';
import { useAuth } from '../contexts/Auth';
import { ativarUsuario, inativarUsuario, paginarUsuarios } from '../services/usuarios';
import HeaderSortClasses from '../utils/sortClass';

function Usuarios() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(20000);

  const columns = [
    {
      isDummyField: true,
      classes: 'text-center',
      formatter: (cell, row) => (
        <ButtonGroup>
          <Button
            key={`btn-usuario-${row.id}`}
            variant="primary"
            size="sm"
            onClick={() => navigate(`/usuarios/${row.id}/alterar-senha`)}
          >
            <i className="fas fa-lock" />
            {' '}
            Senha
          </Button>
          <Button
            key={`btn-usuario-${row.id}`}
            variant="success"
            size="sm"
            onClick={() => handleClickButtonActive(row.id)}
          >
            <i className="fas fa-check" />
            {' '}
            Ativar
          </Button>
          <Button
            key={`btn-usuario-${row.id}`}
            variant="danger"
            size="sm"
            onClick={() => handleClickButtonInactive(row.id)}
          >
            <i className="fas fa-trash" />
            {' '}
            Inativar
          </Button>
        </ButtonGroup>
      )
    },
    {
      dataField: 'email',
      text: 'E-MAIL',
      headerFormatter: HeaderColumn,
      headerSortingClasses: HeaderSortClasses
    },
    {
      dataField: 'role',
      text: 'FUNÇÃO',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      headerSortingClasses: HeaderSortClasses,
      formatter: RoleColumn
    },
    {
      dataField: 'active',
      text: 'ATIVO',
      classes: 'text-center',
      formatter: ActiveColumn,
      headerFormatter: HeaderColumn
    },
    {
      dataField: 'created_at',
      text: 'CRIADO EM',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: DateTimeColumn
    },
    {
      dataField: 'updated_at',
      text: 'ALTERADO EM',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: DateTimeColumn
    }
  ];

  const handleClickButtonActive = async (id) => {
    setInProgress(true);

    const response = await ativarUsuario(auth.user.accessToken, id);
    if (response.status !== 204) {
      setError('Ocorreu um erro inesperado!!!');
    }

    await handleTableChange('active', { page, sizePerPage: pageSize });
  };

  const handleClickButtonInactive = async (id) => {
    setInProgress(true);

    const response = await inativarUsuario(auth.user.accessToken, id);
    if (response.status !== 204) {
      setError('Ocorreu um erro inesperado!!!');
    }

    await handleTableChange('inactive', { page, sizePerPage: pageSize });
  };

  const handleTableChange = async (type, event) => {
    setInProgress(true);
    const response = await paginarUsuarios(
      auth.user.accessToken,
      null,
      event.page,
      event.sizePerPage
    );

    if (response.status !== 200) {
      setError('Ocorreu um erro inesperado!!!');
    }

    setItems(response.data.items);
    setPage(response.data.page);
    setPageSize(response.data.page_size);
    setTotalItems(response.data.total_pages);
    setInProgress(false);
  };

  const handleInitialData = async () => handleTableChange('initial', { page, sizePerPage: pageSize });

  useEffect(() => {
    handleInitialData();
  }, []);

  const handleSizePerPageChange = (sizePerPage) => {
    setPageSize(sizePerPage);
    handleTableChange('changeSizePerPage', { page, sizePerPage });
  };

  const handleNovoUsuario = () => {
    navigate('/usuarios/novo');
  };

  return (
    <Container fluid>
      <Alert
        key="error-alert-danger"
        show={error !== null}
        setShow={setError}
        message={error}
        title="Ops!!!"
        variant="danger"
      />
      <Alert
        key="info-alert-danger"
        show={info !== null}
        setShow={setInfo}
        message={info}
        title=""
        variant="info"
      />
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Administração</small>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small>Usuários</small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Col md={2}>
                <Button
                  size="sm"
                  variant="success"
                  disabled={inProgress}
                  onClick={handleNovoUsuario}
                >
                  <i className="fas fa-plus" />
                  {' '}
                  Novo
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                remote
                bordered
                hover
                condensed
                wrapperClasses="table-responsive"
                headerClasses="text-nowrap text-white bg-primary"
                rowClasses="text-nowrap"
                keyField="email"
                loading={inProgress}
                columns={columns}
                data={items}
                pagination={paginationFactory({
                  page,
                  sizePerPage: pageSize,
                  onSizePerPageChange: handleSizePerPageChange,
                  totalSize: totalItems
                })}
                filter={filterFactory()}
                noDataIndication="Nenhum resultado encontrado!!!"
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: 'rgba(66, 133, 244, 0.3)'
                    })
                  }
                })}
                onTableChange={handleTableChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Usuarios;

/* eslint-disable no-unused-vars */
import React from 'react';

export default function HeaderColumn(column, colIndex, { sortElement, filterElement }) {
  const { text } = column;
  return (
    <div className="text-center">
      <small className="fw-bold">{text}</small>
      {' '}
      {sortElement}
      <br />
      { filterElement }
    </div>
  );
}

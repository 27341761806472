/* eslint-disable react/prop-types */
import React from 'react';
import * as ReactBootstrap from 'react-bootstrap';

function Alert(props) {
  const {
    show, setShow, title, message, variant
  } = props;

  if (show) {
    return (
      <ReactBootstrap.Row className="justify-content-center text-center">
        <ReactBootstrap.Col className="col-xl-5 col-lg-8 col-md-6">
          <ReactBootstrap.Alert variant={variant} onClose={() => setShow(false)} dismissible>
            <ReactBootstrap.Alert.Heading>{title}</ReactBootstrap.Alert.Heading>
            <p>{message}</p>
          </ReactBootstrap.Alert>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    );
  }
}

export default Alert;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';

function isNumeric(num) {
  return !Number.isNaN(num);
}

export default function DinheiroColumn(cell, row) {
  if (cell !== null && cell !== '' && isNumeric(cell)) {
    return (
      <span>
        R$
        {parseFloat(cell).toFixed(2)}
      </span>
    );
  }

  return <span>R$ 0.00</span>;
}

import React from 'react';
import {
  Card, Col, Container, Row
} from 'react-bootstrap';

function Home() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="h3 mb-0 text-gray-800 mx-auto">Home</h1>
        </Col>
      </Row>
      <Row>
        <Col className="col-xl-3 col-md-6 mb-4">
          <Card className="border-left-primary shadow h-100 py-2">
            <Card.Body>
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Associados Cadastrados
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">20.000</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar fa-2x text-gray-300" />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-xl-3 col-md-6 mb-4">
          <Card className="border-left-danger shadow h-100 py-2">
            <Card.Body>
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                    Pagamentos Pendentes
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">R$ 13.577,00</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-exclamation-triangle fa-2x text-gray-300" />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;

/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export function listarSolicitacoesRelatorios(accessToken) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios.get(`${process.env.REACT_APP_API_URL_BASE}/v1/requested-reports`, config);
}

export function consultarSolicitacaoRelatorio(accessToken, id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios.get(`${process.env.REACT_APP_API_URL_BASE}/v1/requested-reports/${id}`, config);
}

export function deletarSolicitacaoRelatorio(accessToken, id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios.delete(`${process.env.REACT_APP_API_URL_BASE}/v1/requested-reports/${id}`, config);
}

export function gerarUrlParaDownloadRelatorio(accessToken, id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios.get(`${process.env.REACT_APP_API_URL_BASE}/v1/requested-reports/${id}/download`, config);
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function RoleColumn(cell, row) {
  if (cell === 'admin') {
    return <Badge bg="success">ADMINISTRADOR</Badge>;
  }

  if (cell === 'manager') {
    return <Badge bg="primary">GERENTE</Badge>;
  }

  return <Badge bg="secondary">VISITANTE</Badge>;
}

/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import login from '../services/login';

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [inError, setInError] = useState(null);

  const handleLogin = (email, password, callback) => {
    setInProgress(true);
    login(email, password)
      .then((response) => {
        if (response.status === 200) {
          const token = jwt_decode(response.data.access_token);
          setUser({
            email: token.sub,
            role: token.role,
            accessToken: response.data.access_token,
            expireAt: moment.unix(token.exp),
            issuedAt: moment.unix(token.iat)
          });
          callback();
        } else if (response.status === 403) {
          setInError('Sua conta está inativa');
        } else {
          setInError('Usuário e ou senha inválidos');
        }
      })
      .catch(() => setInError('Usuário e ou senha inválidos'))
      .finally(() => setInProgress(false));
  };

  const handleLogout = () => {
    setUser(null);
  };

  const value = {
    user, inProgress, inError, setInError, handleLogin, handleLogout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;

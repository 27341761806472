/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-multi-spaces */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/Auth';

function TopBar() {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleClickLogout = () => {
    auth.handleLogout();
    navigate('/login');
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input
            type="text"
            className="form-control bg-light border-0 small"
            placeholder="Procurar por ..."
            aria-label="Search"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => toast.info('Em breve!!!')}
            >
              <i className="fas fa-search fa-sm" />
            </button>
          </div>
        </div>
      </form>
      <ul className="navbar-nav ml-auto">
        <div className="topbar-divider d-none d-sm-block" />
        <li className="nav-item dropdown no-arrow">
          <Button
            onClick={handleClickLogout}
            variant="secondary"
            size="sm"
            className="btn-secondary btn-circle"
          >
            <i className="fas fa-sign-out-alt" />
          </Button>
        </li>
      </ul>
    </nav>
  );
}

export default TopBar;

import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container, Form, Row, Spinner
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/Auth';
import { alterarSenha } from '../services/usuarios';

function AlterarSenha() {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleClickBack = () => navigate('/usuarios');

  const handleValidate = () => {
    let valido = true;
    if (password === '' || confirmPassword === '' || password !== confirmPassword) {
      toast.warn('Verifique Senha/Confirmação Senha');
      valido = false;
    }

    return valido;
  };

  const handleClickSave = async () => {
    setInProgress(true);
    if (handleValidate()) {
      const response = await alterarSenha(
        auth.user.accessToken,
        params.id,
        password,
        confirmPassword
      );

      if (response.status === 500) {
        toast.error('Ocorreu um erro inesperado');
      }

      if (response.status === 400) {
        response.data.forEach((x) => {
          toast.warn(x.msg);
        });
      }

      if (response.status === 204) {
        toast.success('Senha alterada com sucesso!!!');
        handleClickBack();
      }
    }
    setInProgress(false);
  };

  const renderProgress = () => [1, 2, 3].map((number) => (
    <Spinner
      key={`novo-usuario-spinner-${number}`}
      id={`novo-usuario-spinner-${number}`}
      as="span"
      variant="primary"
      animation="grow"
      role="status"
      aria-hidden="true"
    />
  ));

  const renderButtons = () => {
    if (inProgress) {
      return (
        <Card.Footer>
          <Row className="justify-content-center">
            {renderProgress()}
          </Row>
        </Card.Footer>
      );
    }

    return (
      <Card.Footer>
        <Row>
          <Col md={2}>
            <Button
              variant="success"
              size="sm"
              onClick={handleClickSave}
            >
              <i className="fas fa-save" />
              {' '}
              Salvar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              variant="secondary"
              size="sm"
              onClick={handleClickBack}
            >
              <i className="fas fa-arrow-left" />
              {' '}
              Voltar
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Administração</small>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <small>Usuários</small>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <small>
                  Usuário:
                  {params.id}
                </small>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <small>Alteração de Senha</small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Novo Usuário
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      size="sm"
                      type="password"
                      placeholder="********"
                      readOnly={inProgress}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Confirme Senha</Form.Label>
                    <Form.Control
                      size="sm"
                      type="password"
                      placeholder="********"
                      readOnly={inProgress}
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            {renderButtons()}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AlterarSenha;

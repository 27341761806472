import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';

function Layout() {
  document.body.classList.remove('bg-gradient-primary');

  return (
    <ThemeProvider dir="rtl">
      <div id="wrapper">
        <SideBar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopBar />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              closeOnClick
            />
            <Outlet />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Layout;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Container, Row
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DateTimeColumn from '../components/DateTimeColumn';
import FiltrosColumn from '../components/FiltrosColumn';
import HeaderColumn from '../components/HeaderColumn';
import SituacaoSolicitacaoRelatorioColumn from '../components/SituacaoSolicitacaoRelatorioColumn';
import SolicitacaoRelatorioColumn from '../components/SolicitacaoRelatorioColumn';
import { useAuth } from '../contexts/Auth';
import { listarSolicitacoesRelatorios } from '../services/solicitacoesRelatorios';

function SolicitacoesRelatorio() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [items, setItems] = useState([]);

  const columns = [
    {
      isDummyField: true,
      classes: 'text-center',
      formatter: (cell, row) => (
        <Button
          key={`btn-visualizar-solicitacao-relatorio-${row.id}`}
          variant="primary"
          size="sm"
          onClick={() => navigate(`/solicitacoes-relatorio/${row.id}`)}
        >
          Visualizar
          {' '}
          <i className="fas fa-eye" />
        </Button>
      )
    },
    {
      dataField: 'table',
      text: 'RELATÓRIO',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: SolicitacaoRelatorioColumn
    },
    {
      dataField: 'status',
      text: 'SITUAÇÃO',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: SituacaoSolicitacaoRelatorioColumn
    },
    {
      dataField: 'status_date',
      text: 'DATA',
      classes: 'text-center',
      headerFormatter: HeaderColumn,
      formatter: DateTimeColumn
    },
    {
      dataField: 'filters',
      text: 'FILTROS',
      headerFormatter: HeaderColumn,
      formatter: FiltrosColumn
    }
  ];

  const handleTableChange = async (type, event) => {
    setInProgress(true);

    const response = await listarSolicitacoesRelatorios(auth.user.accessToken);

    if (response.status !== 200) {
      toast.error('Ops!!! ocorreu um erro inesperado');
    }

    setItems(response.data);
    setInProgress(false);
  };

  const handleLoadData = async () => handleTableChange('initial');

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <small>Administração</small>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <small>Solicitações de Relatório das Últimas 24 horas</small>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Col md={2}>
                <Button
                  size="sm"
                  variant="secondary"
                  disabled={inProgress}
                  onClick={handleLoadData}
                >
                  <i className="fas fa-redo" />
                  {' '}
                  Atualizar
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <BootstrapTable
                remote
                bordered
                hover
                condensed
                wrapperClasses="table-responsive"
                headerClasses="text-nowrap text-white bg-primary"
                rowClasses="text-nowrap"
                keyField="id"
                loading={inProgress}
                columns={columns}
                data={items}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: 'rgba(66, 133, 244, 0.3)'
                    })
                  }
                })}
                noDataIndication="Nenhum resultado encontrado!!!"
                onTableChange={handleTableChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SolicitacoesRelatorio;
